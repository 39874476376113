import React from 'react';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const NavBar = ({ user, signOut }) => {
  const navigate = useNavigate();
  const displayName = user?.attributes?.['custom:username'] || user?.attributes?.email || 'User';

  const handleSignOut = () => {
    signOut();
    navigate('/');
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          MedSim
        </Typography>
        <Typography variant="body1" sx={{ mr: 2 }}>
          Welcome, {displayName}
        </Typography>
        <Button color="inherit" onClick={handleSignOut}>
          Logout
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
