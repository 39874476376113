import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Card, CardContent, CardActions, Typography, Grid } from '@mui/material';
import Layout from './Layout';

const Dashboard = () => {
  const navigate = useNavigate();

  return (
    <Layout>
      <Typography variant="h4" gutterBottom>Welcome to MedSim</Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>Start New Simulation</Typography>
              <Typography variant="body2" color="text.secondary">
                Choose a scenario to practice your communication skills
              </Typography>
            </CardContent>
            <CardActions>
              <Button 
                variant="contained" 
                onClick={() => navigate('/scenarios/')}
              >
                Choose Scenario
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>My Progress</Typography>
              <Typography variant="body2" color="text.secondary">View your performance and improvement</Typography>
            </CardContent>
            <CardActions>
              <Button variant="contained" onClick={() => navigate('/progress')}>View Progress</Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>Recent Simulations</Typography>
              <Typography variant="body2" color="text.secondary">Review your latest practice sessions</Typography>
            </CardContent>
            <CardActions>
              <Button variant="contained" onClick={() => navigate('/history')}>View History</Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Dashboard;