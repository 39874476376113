import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import { Authenticator, useAuthenticator, TextField } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import awsExports from './aws-exports';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import NavBar from './components/NavBar';
import Dashboard from './components/Dashboard';
import ScenarioSelection from './components/ScenarioSelection';
import SimulationInterface from './components/SimulationInterface';
import SimulationResult from './components/SimulationResult';

Amplify.configure(awsExports);

const theme = createTheme();

function SignUpFormFields() {
  const { validationErrors } = useAuthenticator((context) => [context.validationErrors]);

  return (
    <>
      <TextField
        label="Email Address"
        name="username"
        placeholder="Enter your email address"
        type="email"
        isRequired={true}
        errorMessage={validationErrors.username}
      />
      <TextField
        label="Password"
        name="password"
        placeholder="Enter your password"
        type="password"
        isRequired={true}
        errorMessage={validationErrors.password}
      />
      <TextField
        label="Confirm Password"
        name="confirm_password"
        placeholder="Confirm your password"
        type="password"
        isRequired={true}
        errorMessage={validationErrors.confirm_password}
      />
      <TextField
        label="Display Name"
        name="custom:username"
        placeholder="Enter your display name"
        isRequired={true}
        errorMessage={validationErrors['custom:username']}
      />
    </>
  );
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Authenticator
usernameAlias="email"
        components={{
          SignUp: {
            FormFields: SignUpFormFields,
          },
        }}
        formFields={{
          signIn: {
            username: {
              label: 'Email Address',
              placeholder: 'Enter your email address',
              type: 'email',
            },
          },
        }}
      >
        {({ signOut, user }) => (
          <Router>
            <NavBar user={user} signOut={signOut} />
            <Routes>
              <Route path="/" element={<Navigate to="/dashboard" replace />} />
              <Route path="/dashboard" element={<Dashboard user={user} />} />
              <Route
                path="/scenarios"
                element={<ScenarioSelection user={user} />}
              />
              <Route
                path="/simulation"
                element={<SimulationInterface user={user} />}
              />
              <Route
                path="/simulation-result"
                element={<SimulationResult user={user} />}
              />
            </Routes>
          </Router>
        )}
      </Authenticator>
    </ThemeProvider>
  );
}

export default App;
