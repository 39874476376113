import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { 
  Typography, 
  Box, 
  Container, 
  Button, 
  CircularProgress, 
  Paper,
  Snackbar,
  Alert 
} from '@mui/material';
import ReactMarkdown from 'react-markdown';
import { styled } from '@mui/material/styles';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  backgroundColor: theme.palette.background.default,
}));

const SimulationResult = () => {
  const [feedback, setFeedback] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
const [openSnackbar, setOpenSnackbar] = useState(false);
const navigate = useNavigate();
const location = useLocation();

  useEffect(() => {
    const fetchFeedback = async () => {
      const sessionId = location.state?.sessionId;
      if (!sessionId) {
        setError('Session ID not found. Unable to fetch feedback.');
        setLoading(false);
        return;
      }

      try {
// Add a small delay to ensure the feedback has been generated
        await new Promise(resolve => setTimeout(resolve, 2000));
        
        const response = await axios.get(`http://localhost:5000/api/coach_feedback/${sessionId}`);
        setFeedback(response.data.feedback);
        setLoading(false);
      } catch (err) {
console.error('Error fetching feedback:', err);
        setError('Failed to fetch feedback. Please try again later.');
        setLoading(false);
setOpenSnackbar(true);
      }
    };

    fetchFeedback();
  }, [location.state]);

  const handleGoBack = () => {
    navigate('/dashboard');
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  if (loading) return (
    <Container maxWidth="md" sx={{ textAlign: 'center', mt: 4 }}>
      <CircularProgress />
    </Container>
  );
  
    return (
    <Container maxWidth="md">
      <Box sx={{ my: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
          Simulation Result
        </Typography>
      <StyledPaper elevation={3}>
        <Typography variant="h5" gutterBottom>
            Coach Feedback
          </Typography>
      <Box sx={{ mt: 2 }}>
{feedback ? (
            <ReactMarkdown>{feedback}</ReactMarkdown>
) : (
              <Typography color="text.secondary">
                No feedback available at this time. Please try again later.
              </Typography>
            )}
</Box>
        </StyledPaper>
      <Button
variant="contained"
          color="primary"
        onClick={handleGoBack}
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      >
        Go back to the dashboard
      </Button>
    </Box>
<Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default SimulationResult;