import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import io from 'socket.io-client';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { 
  Typography, 
  Box, 
  TextField, 
  Button, 
  Paper, 
  Container,
  IconButton
} from '@mui/material';
import { styled } from '@mui/material/styles';
import SendIcon from '@mui/icons-material/Send';
import MicIcon from '@mui/icons-material/Mic';
import StopIcon from '@mui/icons-material/Stop';

const WEBSOCKET_URL = 'http://localhost:5000';
const API_BASE_URL = 'http://localhost:5000/api';

const MessageContainer = styled(Paper)(({ theme, isUser }) => ({
  padding: theme.spacing(1),
  marginBottom: theme.spacing(1),
  backgroundColor: isUser ? theme.palette.primary.light : theme.palette.grey[100],
  color: isUser ? theme.palette.primary.contrastText : theme.palette.text.primary,
  alignSelf: isUser ? 'flex-end' : 'flex-start',
  maxWidth: '80%',
}));

const SimulationInterface = ({ scenario }) => {
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const [isSimulationEnded, setIsSimulationEnded] = useState(false);
    const [sessionId, setSessionId] = useState(null);
    const [isRecording, setIsRecording] = useState(false);
    const socketRef = useRef(null);
    const audioRef = useRef(null);
    const messagesEndRef = useRef(null);
    const mediaRecorderRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        const newSessionId = uuidv4();
        setSessionId(newSessionId);
        setupSocket(newSessionId);

        return () => {
            if (socketRef.current) {
                socketRef.current.disconnect();
            }
        };
    }, []);

    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [messages]);

    const setupSocket = (newSessionId) => {
        socketRef.current = io(WEBSOCKET_URL, { 
            transports: ['websocket'],
            reconnection: true,
            reconnectionAttempts: 5,
            reconnectionDelay: 1000,
        });

        socketRef.current.on('connect', () => startSimulation(newSessionId));
        socketRef.current.on('new_message', (message) => setMessages(prev => [...prev, message]));
        socketRef.current.on('simulation_ended', () => setIsSimulationEnded(true));
        socketRef.current.on('new_audio_ready', (data) => playAudio(data.audioPath));
    };

    const startSimulation = async (sessionId) => {
        await axios.post(`${API_BASE_URL}/start_simulation`, { scenario, sessionId });
            };

    const playAudio = (audioPath) => {
                if (audioRef.current) {
            const fileName = audioPath.split('/').pop();
            audioRef.current.src = `${API_BASE_URL}/audio/${fileName}`;
            audioRef.current.play().catch(e => console.error('Error playing audio:', e));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
                if (input.trim() && !isSimulationEnded) {
                        socketRef.current.emit('user_input', { content: input.trim(), sessionId: sessionId });
            setInput('');
        }
    };

    const handleGoToResult = () => {
        navigate('/simulation-result', { state: { sessionId: sessionId } });
    };
    
    const startRecording = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            mediaRecorderRef.current = new MediaRecorder(stream);
            const chunks = [];

            mediaRecorderRef.current.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    chunks.push(event.data);
                }
            };

            mediaRecorderRef.current.onstop = () => {
                const blob = new Blob(chunks, { type: 'audio/wav' });
                                sendAudioToServer(blob);
            };

            mediaRecorderRef.current.start();
            setIsRecording(true);
        } catch (error) {
            console.error('Error starting recording:', error);
        }
    };

    const stopRecording = () => {
        if (mediaRecorderRef.current && isRecording) {
            mediaRecorderRef.current.stop();
            setIsRecording(false);
        }
    };

    const sendAudioToServer = async (blob) => {
        const formData = new FormData();
        formData.append('audio', blob, 'audio.wav');
        formData.append('sessionId', sessionId);

        try {
            const response = await axios.post(`${API_BASE_URL}/transcribe_audio`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
            setInput(response.data.text);
            handleSubmit({ preventDefault: () => {} });
        } catch (error) {
            console.error('Error sending audio to server:', error);
        }
    };
 
    const renderMessage = (msg, index) => {
        return (
            <Box sx={{ display: 'flex', justifyContent: msg.isUser ? 'flex-end' : 'flex-start', mb: 1 }}>
                <MessageContainer isUser={msg.isUser}>
                    <Typography variant="body1">
                        <strong>{msg.sender}:</strong> {msg.content}
                    </Typography>
                </MessageContainer>
            </Box>
        );
    };

    return (
        <Container maxWidth="md">
            <Box sx={{ my: 4 }}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Medical Simulation
                </Typography>
                                <Typography variant="body1">Scenario: {scenario}</Typography>
                            </Box>
            <Paper 
                elevation={3} 
                sx={{ 
                    height: 400, 
                    overflowY: 'auto', 
                    p: 2, 
                    mb: 2, 
                    display: 'flex', 
                    flexDirection: 'column' 
                }}
            >
                {messages.map((msg, index) => renderMessage(msg, index))}
                <div ref={messagesEndRef} />
            </Paper>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <TextField 
                    fullWidth
                    variant="outlined"
                    value={input} 
                    onChange={(e) => setInput(e.target.value)} 
                    placeholder="Type your message..."
                    disabled={isSimulationEnded || isRecording}
                    onKeyPress={(e) => e.key === 'Enter' && handleSubmit(e)}
                    sx={{ mr: 1 }}
                />
                <IconButton 
                    color={isRecording ? "secondary" : "primary"}
                    onClick={isRecording ? stopRecording : startRecording}
                    disabled={isSimulationEnded}
                >
                    {isRecording ? <StopIcon /> : <MicIcon />}
                </IconButton>
                <IconButton 
                    color="primary"
                    onClick={handleSubmit}
                    disabled={isSimulationEnded || isRecording || !input}
                >
                    <SendIcon />
                </IconButton>
            </Box>
            {isSimulationEnded && (
                <Box sx={{ mt: 2 }}>
                    <Button 
                        variant="contained" 
                        color="primary"
                        onClick={handleGoToResult}
                    >
                        Go to Result
                    </Button>
                </Box>
            )}
            <audio ref={audioRef} style={{ display: 'none' }} />
        </Container>
    );
};

export default SimulationInterface;