import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Card, CardContent, CardActions, Typography, Grid, CircularProgress } from '@mui/material';
import Layout from './Layout';

function ScenarioSelection({ updateSimulationData }) {
  const [scenarios, setScenarios] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    console.log('ScenarioSelection: Fetching scenarios');
    setLoading(true);
    fetch('/api/scenarios/')
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        console.log('ScenarioSelection: Received scenarios:', data);
        setScenarios(data);
        setLoading(false);
      })
      .catch(e => {
        console.error('ScenarioSelection: Error fetching scenarios:', e);
        setError(e.message);
        setLoading(false);
      });
  }, []);

  const handleScenarioSelect = (scenario) => {
    console.log('ScenarioSelection: Selected scenario', scenario);
    updateSimulationData('scenario', scenario.name);
    navigate('/simulation');
  };

  if (loading) {
    return <Layout><CircularProgress /></Layout>;
  }

  if (error) {
    return <Layout><Typography color="error">Error: {error}</Typography></Layout>;
  }

  return (
    <Layout>
      <Typography variant="h4" gutterBottom>Select a Scenario</Typography>
      <Grid container spacing={3}>
        {scenarios.map((scenario, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>{scenario.name}</Typography>
                <Typography variant="body2" color="text.secondary">{scenario.description}</Typography>
                              </CardContent>
              <CardActions>
                <Button variant="contained" onClick={() => handleScenarioSelect(scenario)}>Choose Scenario</Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Layout>
  );
}

export default ScenarioSelection;